<template>
    <div class="page abs-9">
        <full-page ref="fullpage" :options="options" class="team-fullpage noboxsizing">
        <div class="row team fade-in section">
            <div class="col-md-4 col-xs-12 nopadding nomargin team-left" :class="{ 'zIndexAbove' : !isShowTeamInfo, 'zIndexBelow' : isShowTeamInfo }">
                <div class="team-meet visible-sm visible-xs hidden-md hidden-lg fade-in" v-if="isActiveTeam == null">
                    <h1 class="slide-top" v-html="translate[language].title"></h1>
                </div>
                <div class="row avatar-list" >
                    <div class="avatar-container" v-for="index of 4" :key="index"  >
                        <div  class="avatar col-xs-12  col-md-12 nopadding" @click="showTeamInfo( index -1 ) " >
                            <img :src="'/images/avatar-'+index+'.jpg'" alt="" :class="['scale-in-center animation delay-'+index ]" >
                        </div>
                    </div>
                </div> 
            </div>
            <div class="col-md-8 nopadding col-xs-12 team-right" :class="{ 'zIndexAbove' : isShowTeamInfo, 'zIndexBelow' : !isShowTeamInfo }"> 
                <div class="team-meet hidden-sm hidden-xs visible-md visible-lg fade-in" v-if="!isShowTeamInfo">
                     <h1 class="slide-top" v-html="translate[language].title"></h1>
                </div>
                <div class="team-info " v-show="isShowTeamInfo" :class="{'slide-in-right' : !isShowTeamInfo}" >
                    <div class="row hidden-md hidden-lg visible-sm visible-xs" style=" position: absolute; right: 0px; top: 0; ">
                        <a href="#" @click="isShowTeamInfo=false" class=" pull-right" style="margin-right:20px"> <img src="/images/icon-close.png"> </a>
                    </div>
                    <div class="team-title">
                        <h2 class="fade-in">{{ translate[language].teamData[isActiveTeam].name }}</h2>
                        <h3 class="fade-in"> {{translate[language].teamData[isActiveTeam].slogan  }}</h3>
                        <div class=" hidden-md hidden-lg visible-sm visible-xs hide"  v-if="false">
                            <a :href="translate[language].teamData[isActiveTeam].social.linkedin" target="_blank"  class="social colored-linkedin inline-block margin-left slide-top animation delay-3"></a>
                        </div>
                    </div>
                    <div class="team-body">
                        <div class="fade-in"  v-html="translate[language].teamData[isActiveTeam].bullets"> </div>
                       <div class=" visible-md visible-lg hidden-sm hidden-xs " v-if="false">
                            <a :href="translate[language].teamData[isActiveTeam].social.linkedin" target="_blank" class="social colored-linkedin inline-block margin-left slide-top animation delay-3"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </full-page >
    </div>
</template>
<script>
export default {
    data(){
        return {
            isActiveTeam : 0,
            isShowTeamInfo : false,
            language : ( this.$cookies.get('language') == null  ? 'english'  : this.$cookies.get('language') ),
            options: {
                scrollBar: false,
                responsiveSlides: true,
                easingcss3 : 'cubic-bezier(0.455, 0.310, 0.120, 1.020)',
                licenseKey: '',
                scrollingSpeed : 900,
                fitToSectionDelay: 1000,
                css3: true,
            },
            translate:{
                spanish: {
                    title: 'CONOCE <strong> AL EQUIPO </strong>',
                    teamData: [
                         { name : "FEDERICO RÍOS PATRÓN",
                        slogan: 'Una precisa visión a futuro, jamás debe ser borrosa',
                        bullets: "<ul>"+ 
                                        "<li> PROFESIONAL EN EL ÁMBITO DE BIENES RAÍCES</li>"+
                                        "<li> DIRECTOR GENERAL JURÍDICO EN GE CAPITAL REAL ESTATE</li>"+
                                        "<li> ASOCIADO EN MAYER, BROWN Y PLATT (OFICINA DE CHICAGO) Y ASOCIADO EN BUFETE DE ABOGADOS JÁUREGUI, NAVARRETE Y NADER</li>"+
                                        "<li> ACTUALMENTE ES MIEMBRO DE JUNTAS DIRECTIVAS EN MÉXICO Y ESTADOS UNIDOS</li>"+
                                    "</ul>",
                        social: { linkedin : "https://www.linkedin.com/in/federico-rios-patron-98830019" }
                        },
                         { name : "ADRIÁN PANDAL",
                        slogan: 'LAS SOLUCIONES LLEGAN A TRAVÉS DE LA CONCILIACIÓN Y EL ENTENDIMIENTO MUTUO',
                        bullets: "<ul>"+ 
                                        "<li> 23 AÑOS DE EXPERIENCIA EN DESARROLLO URBANO, BIENES RAÍCES, INFRAESTRUCTURA Y APOYO SOCIAL EN GRUPO CARSO</li>"+
                                        "<li> ESTRUCTURACIÓN, NEGOCIACIÓN Y COORDINACIÓN DE MÁS DE 120 PROYECTOS INMOBILIARIOS EN INMUEBLES CARSO (INCARSO)</li>"+
                                        "<li> DIRECTOR DE FUNDACIÓN DEL CENTRO HISTÓRICO Y RESPONSABLE DE LA RECONSTRUCCIÓN DE 75 EDIFICIOS DECLARADOS PATRIMONIO DE LA HUMANIDAD</li>"+
                                    "</ul>",
                        social : { linkedin : "https://www.linkedin.com/in/adrian-pandal-9005b73b" }
                        },
                          { name : "GERARDO RÍOS PATRÓN",
                        slogan: 'LOS VISIONARIOS POR LO GENERAL SON ACUSADOS DE SER CIEGOS',
                        bullets: "<ul>"+ 
                                        "<li> 28 AÑOS EN UNA EMPRESA FORTUNE 500, LIDERANDO NEGOCIOS REGIONALES MULTIBILLONARIOS EN AMÉRICA LATINA</li>"+
                                        "<li> LÍDER VISIONARIO QUE GENERÓ NUEVAS OPORTUNIDADES DE MERCADO Y CREÓ NEGOCIOS EN 11 PAÍSES DIFERENTES</li>"+
                                        "<li> LÍDER EN EMPRESAS SOCIALES Y DE TRANSFORMACIÓN EN AMÉRICA LATINA, GENERANDO PROSPERIDAD EN VARIAS COMUNIDADES Y PAÍSES (MÉXICO, VENEZUELA, ARGENTINA, BRASIL Y PANAMÁ)</li>"+
                                    "</ul>",
                        social : { linkedin : "https://www.linkedin.com/in/gerardo-rios-patron" }
                        },
                       
                         { name : "JOSÉ MANUEL GARCÍA COLINA",
                        slogan: 'SI SE CONSTRUYE CON RESPONSABILIDAD, LA PROSPERIDAD ES SEGURA',
                        bullets: "<ul>"+ 
                                        "<li> DESARROLLO INMOBILIARIO EN MÁS DE MEDIO MILLÓN DE PIES CUADRADOS PREMIUM</li>"+
                                        "<li> FUNDADOR DE VARIAS FIRMAS DE BIENES RAÍCES</li>"+
                                        "<li> SOCIO FUNDADOR DE GRUPO FINMOBILIARIA CON 25 PROYECTOS DE BIENES RAÍCES</li>"+
                                        "<li> 310 UNIDADES Y MÁS DE 50 MILLONES DE DÓLARES EN VENTAS</li>"+
                                        "<li> CONSTRUCCIÓN Y REPOSICIONAMIENTO DE MÁS DE 20 EDIFICIOS Y PROYECTOS PREMIUM </li>"+
                                    "</ul>",
                        social : { linkedin : "https://www.linkedin.com/in/jose-manuel-garcia-colina-7a1270" }
                        },
                    ]
                },
                english : {
                    title: 'MEET <strong>OUR TEAM</strong>',
                    teamData: [
                          { name : "FEDERICO RIOS PATRON",
                        slogan: 'A PRECISE VISION OF THE FUTURE SHOULD NEVER BE BLURRY',
                        bullets: "<ul>"+ 
                                        "<li> SEASONED PROFESSIONAL IN THE FIELD OF REAL ESTATE</li>"+
                                        "<li> GENERAL LEGAL COUNSEL AT GE CAPITAL</li>"+
                                        "<li> FOREIGN ASSOCIATE MAYER, BROWN AND PLATT (CHICAGO OFFICE) AND ASSOCIATE AT JAUREGUI NAVARRETE & NADER LAW FIRM</li>"+
                                        "<li> CURRENTLY SERVES AS A BOARD MEMBER IN BOTH MEXICO AND THE US</li>"+
                                    "</ul>",
                        social: { linkedin : "https://www.linkedin.com/in/federico-rios-patron-98830019" }
                        },
                         { name : "ADRIAN PANDAL",
                        slogan: 'SOLUTIONS ARE ACHIEVED THROUGH CONCILIATION AND COMMON UNDERSTANDING',
                        bullets: "<ul>"+ 
                                        "<li> 23 YEARS OF EXPERIENCE IN URBAN DEVELOPMENT, REAL ESTATE, INFRASTRUCTURE AND SOCIAL SUPPORT PROGRAMS AT CARSO</li>"+
                                        "<li> COORDINATION AND LEADERSHIP OF THE DEVELOPMENT OF MORE THAN 120 REAL ESTATE PROJECTS AT INMUEBLES CARSO (INCARSO)</li>"+
                                        "<li> CEO OF FUNDACION DEL CENTRO HISTORICO DE LA CIUDAD DE MEXICO LEADING THE RECONSTRUCTION OF 75 BUILDINGS DECLARED HERITAGE OF HUMANITY</li>"+
                                    "</ul>",
                        social : { linkedin : "https://www.linkedin.com/in/adrian-pandal-9005b73b" }
                        },
                         { name : "GERARDO RIOS PATRON",
                        slogan: 'VISIONARIES ARE USUALLY ACCUSED OF BEING BLIND',
                        bullets: "<ul>"+ 
                                        "<li> 28 YEARS IN A FORTUNE 500 COMPANY, LEADING MULTIBILLION REGIONAL BUSINESSES ACROSS LATIN AMERICA</li>"+
                                        "<li> VISIONARY LEADER THAT OPENED NEW MARKET OPPORTUNITIES AND TURNED AROUND BUSINESSES IN 11 DIFFERENT COUNTRIES</li>"+
                                        "<li> LEADER IN SOCIAL ENTERPRISE AND TRANSFORMATION ACROSS LATIN AMERICA GENERATING PROSPERITY IN VARIOUS COMMUNITIES AND COUNTRIES (MEXICO, VENEZUELA, ARGENTINA, BRAZIL AND PANAMA)</li>"+
                                    "</ul>",
                        social : { linkedin : "https://www.linkedin.com/in/gerardo-rios-patron" }
                        },
                       
                      
                         { name : "JOSE MANUEL GARCIA COLINA",
                        slogan: 'WHEN ONE BUILDS WITH RESPONSIBILITY THE OUTCOME IS PROSPERITY',
                        bullets: "<ul>"+ 
                                        "<li> DEVELOPED MORE THAN A MILLION SQUARE FEET IN PROPERTIES</li>"+
                                        "<li> FOUNDER OF SEVERAL REAL ESTATE FIRMS</li>"+
                                        "<li> FOUNDING PARTNER GRUPO FINMOBILIARIA WITH 25 REAL ESTATE PROJECTS</li>"+
                                        "<li> 310 UNITS, AND MORE THAN 50 MILLION DOLLARS IN SALES</li>"+
                                        "<li> CONSTRUCTION AND REPOSITIONING OF MORE THAN 20 PREMIUM BUILDINGS AND PROJECTS</li>"+
                                    "</ul>",
                        social : { linkedin : "https://www.linkedin.com/in/jose-manuel-garcia-colina-7a1270" }
                        },
                     
                    ]
                }
            },
           
            
        }
    },
    methods: {
        showTeamInfo( integer ){
            this.isActiveTeam =  integer;
            this.isShowTeamInfo = true
        },
     checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
    },
    created(){
        this.checkLanguage()
    }
}
</script>


